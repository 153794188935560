@use "@els/els-styleguide-core/scss/core" as *;

.c-els-pill {
  color: $els-color-text;
  margin-left: $els-base-space;
  padding-bottom: 0.25 * $els-base-space;

  &--not-published {
    background-color: $els-color-extended-orange-0;
  }

  &--recently-published {
    background-color: $els-color-extended-blue-0;
  }
}
