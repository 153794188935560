@use "@els/els-styleguide-core/scss/core" as *;
@use "~@els/els-ui-common-react/module/assets/scss/common/utilities" as *;

.c-scm-user-icon {
  background: $els-color-n9;
  border-radius: 100%;
  height: $els-space-2x1o2;
  width: $els-space-2x1o2;
  text-align: center;
  color: white;
  position: relative;

  &__icon {
    display: block;
    position: absolute;
    top: $els-space-1o2;
    left: $els-space-1o2;
  }
}
