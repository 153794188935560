@use "../../../node_modules/@els/els-styleguide-core/scss/core" as *;
@use "../../../node_modules/@els/els-ui-common-react/module/assets/scss/common/utilities" as *;

.c-ama-question-preview {

  &__content {
    padding: $els-space-2x1o2 $els-space * 16;

    @include mq($until: desktop) {
      padding: $els-space $els-space * 8;
    }

    @include mq($until: mobile) {
      padding: $els-space $els-space-2x;
    }
  }

  &__title {
    max-width: $els-space-1x * 60;
    margin-bottom: $els-space-1x1o2;
  }
}

.c-ama-assignment-preview {
  margin-top: $els-space-1x1o2;
  margin-bottom: $els-space-1x;
  max-width: $els-space-1x * 60;

  &__content-card {
    padding: $els-space-1o8 * 15;
    border: $els-thin-keyline-width solid $els-color-n3;
    margin-bottom: $els-space-1x1o2;
  }
}
