@use "@els/els-styleguide-core/scss/core" as *;

$header-modal-sidebar-animation-duration: $els-transition-duration-default; //.5s;
$header-modal-sidebar-width: $els-space * 20;
$els-header-modal-menu-item-font-size: $els-fs-body-large;
$els-header-modal-menu-font-color: $els-color-n9;

@keyframes els-header-modal-sidebar-open {
  0% {
    right: -$header-modal-sidebar-width;
  }
  100% {
    right: 0;
  }
}

@keyframes els-header-modal-sidebar-close {
  0% {
    right: 0
  }
  100% {
    right: -$header-modal-sidebar-width
  }
}

.c-els-header-modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
  background: transparentize($els-color-n2, (1-($els-transparency-85)));

  @include mq($until: mobile) {
    display: block;
  }

  &__sidebar {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    width: $header-modal-sidebar-width;
    height: 100%;
    z-index: 99999;
    overflow-y: auto;
    overflow-x: hidden;
    animation-duration: $header-modal-sidebar-animation-duration;
    background: white;
    padding: $els-space;
  }

  &__close {
    float: right;
    margin: 0 0 $els-space $els-space;
  }

  &__close-trigger {

  }

  &__close-icon {
    color: $els-header-modal-menu-font-color;
  }

  &__menu-list {
    display: block;
  }

  &__menu-item {
    display: block;
    margin-bottom: $els-space;
  }

  &__menu-link {
    font-size: $els-header-modal-menu-item-font-size;
    color: $els-header-modal-menu-font-color;

    &:hover {
      color: $els-header-modal-menu-font-color;
    }
  }

  &__close-icon {
    width: $els-space-1x1o2;
    height: $els-space-1x1o2;
  }

  &--active {
    .c-els-header-modal__sidebar {
      right: 0
    }
  }

  &--animate-open {
    .c-els-header-modal__sidebar {
      animation-name: els-header-modal-sidebar-open;
      right: 0
    }
  }

  &--animate-close {
    .c-els-header-modal__sidebar {
      animation-name: els-header-modal-sidebar-close;
      right: -$header-modal-sidebar-width;
    }
  }
}
