// This file is copied from leyden project since it is not yet exported
// https://github.com/elsevier-health/health-leyden/blob/master/packages/react/els-react--flyout/src/component/Flyout.scss
// TODO: Source this scss file from Leyden package when it is independently exported

$scm-flyout-background-color: #fff;
$scm-flyout-border-color: #cecece;
$scm-flyout-className: '.c-els-flyout';

#{$scm-flyout-className} {
  display: inline-block;

  &__container {
    background-color: $scm-flyout-background-color;
    border: 1px solid $scm-flyout-border-color;
  }

  &__reference {
    display: inline-block;
    cursor: pointer;
  }
}
