$els-color-n1: #eeeeee;
$els-color-n7: #737373;
$u-els-fill-extended-purple-1: #edd9ff;
$u-els-fill-extended-purple-5: #bf8cf2;
$u-els-fill-extended-purple-9: #613191;

.u-els-fill-n1 {
  fill: $els-color-n1;
}

.u-els-fill-n7 {
  fill: $els-color-n7;
}

.u-els-fill-extended-purple-1 {
  fill: $u-els-fill-extended-purple-1;
}

.u-els-fill-extended-purple-5 {
  fill: $u-els-fill-extended-purple-5;
}

.u-els-fill-extended-purple-9 {
  fill: $u-els-fill-extended-purple-9;
}
