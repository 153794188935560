@use "@els/els-styleguide-core/scss/core" as *;

.c-els-ebook-pages {
  border: $els-thin-keyline-width solid $els-color-n5;
  max-height: 40vh;
  overflow: scroll;
  padding: $els-space $els-space-2x;

  &__pages-input-container {
    margin: $els-space 0;
    padding-left: 1.75 * $els-space;
  }

  &__pages-input {
    max-width: $els-space * 10;
  }
}
