@use "../../../../node_modules/@els/els-styleguide-core/scss/core" as *;

.c-ama-eaq-assignment-detail-report {
  &__description-block {
    background-color: $els-color-n0;
    padding: $els-space $els-space-2x;
  }
  &__table {
    margin: 0 $els-space 0 $els-space;
  }
}
