//TODO: Migrate these styles to be shared

@use "@els/els-styleguide-core/scss/core" as *;

$els-page-cover-header-height-large: $els-space * 5; //TODO: Inherit this from $els-header-height-large
$els-page-cover-header-height-small: $els-space * 3; //TODO: Inherit this from $els-header-height-small

.c-els-page-cover-header {
  border-bottom: $els-thick-keyline-width solid $els-color-n0;
  background: white;
  padding: 0 $els-space-2x;
  transition: all $els-transition-duration-default ease;

  @include mq($until: mobile) {
    padding: 0 $els-space-1x;
  }

  &__close-trigger {
    display: block;
    border: none;
  }

  &__container {
    min-height: $els-page-cover-header-height-large;

    @include mq($until: mobile) {
      min-height: $els-page-cover-header-height-small;
    }
  }
}
